window.onload = event => {

    const password_field = document.querySelector('#password');
    const password_eye = document.querySelector('.password_eye');
    password_field.value = "";
    password_eye.onclick = () => {
        if(password_field.type === "password"){
            password_field.type = "text"
        }else{
            password_field.type = "password"
        }
        password_eye.classList.toggle('eye-close')
    }

};
